
export default {
  name: 'Footer',
  data() {
    return {
      links: [
        { name: '价格', path: '/price' },
        { name: '帮助中心', path: '/help' },
        { name: '隐私政策', path: '/legal/privacy' },
      ],
    }
  },
  computed: {
    isPrivacyPage() {
      return this.$nuxt.$route.path.replace('/' + this.lang.code, '').replace(/\/$/, '') === '/legal/privacy'
    },
    // 由于隐私页要特殊处理
    currentPageLang() {
      return this.isPrivacyPage ? 'en' : this.lang
    },
    lang() {
      return this.$i18n.locales.find((i) => i.code === this.$i18n.locale)
    },
    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale)
    },
  },
}
