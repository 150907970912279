
import throttle from 'lodash/throttle'
export default {
  name: 'Header',
  data() {
    return {
      opacity: 0,
      showLangList: false,
      showNav: false,
      navs: [
        {
          name: '首页',
          path: '/',
        },
        {
          name: '价格',
          path: '/price',
        },
        {
          name: '帮助中心',
          path: '/help',
        },
      ],
    }
  },
  computed: {
    isPrivacyPage() {
      return this.$nuxt.$route.path.replace('/' + this.lang.code, '').replace(/\/$/, '') === '/legal/privacy'
    },
    // 由于隐私页要特殊处理
    currentPageLang() {
      return this.isPrivacyPage ? 'en' : this.lang
    },
    active() {
      return this.$nuxt.$route.path.replace('/' + this.lang.code, '').replace(/\/$/, '') || '/'
    },
    lang() {
      return this.$i18n.locales.find((i) => i.code === this.$i18n.locale)
    },
    availableLocales() {
      // return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale)
      return this.$i18n.locales
    },
    isHelpDetail() {
      return this.$nuxt.$route.path.includes('/help/detail')
    },
  },
  watch: {
    $route(to, from) {
      this.showNav = false
      this.showLangList = false
    },
  },
  mounted() {
    if (window.scrollY > 200) {
      this.opacity = 1
    }
    this.$nextTick(() => {
      window.addEventListener('scroll', this.onscroll)
    })
  },
  beforeRouteEnter() {
    this.showNav = false
    this.showLangList = false
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onscroll)
  },
  methods: {
    onscroll: throttle(function () {
      const offset = window.pageYOffset
      if (offset > 200) {
        this.opacity = 1
        return
      }
      this.opacity = (offset / 200).toFixed(1)
    }, 100),
    toggleLangList() {
      this.showLangList = !this.showLangList
    },
    toggleNavShow() {
      this.showNav = !this.showNav
    },
  },
}
