import Vue from 'vue'
import progressive from '~/components/progressive-image'
import '~/components/progressive-image/style.css'

if (process.client) {
  Vue.use(progressive, {
    removePreview: false,
    lazy: false,
  })
}
