
const isExternal = (path) => /^(https?:|mailto:|tel:)/.test(path)

export default {
  name: 'SvgIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconComponent() {
      return this.icons[this.name]
    },
    isExternal() {
      return isExternal(this.name)
    },
    iconName() {
      return `#icon-${this.name}`
    },
    styleExternalIcon() {
      return {
        mask: `url(${this.name}) no-repeat 50% 50%`,
        '-webkit-mask': `url(${this.name}) no-repeat 50% 50%`,
      }
    },
  },
}
