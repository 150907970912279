const prefix =
  {
    dev: 'dev-',
    test: 'test-',
    prod: '',
  }[process.env.RUNTIME_ENV] ?? 'test-'

const getLanguageCode = (locale) => {
  const localeObj = locale.locales.find((item) => item.code === locale.locale)
  return (localeObj.iso || 'en-US').split('-').join('_')
}

export default {
  login: (locale) => {
    window.open(`https://${prefix}app.kamichat.im/login?from=officalwebsite&languageCode=${getLanguageCode(locale)}`)
  },
  registry: (locale) => {
    window.open(`https://${prefix}app.kamichat.im/registry?from=officalwebsite&languageCode=${getLanguageCode(locale)}`)
  },
  home: (locale) => {
    window.open(`https://${prefix}app.kamichat.im?from=officalwebsite&languageCode=${getLanguageCode(locale)}`)
  },
}
